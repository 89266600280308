import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { isEmpty } from 'lodash'
import { useFeature } from 'toggled'

import { Catalog } from '~/components'
import { featuredProductsQuery } from '~/gql/queries'
import { useVendor } from '~/hooks'
import { useScrollRestoration } from '~/hooks/use-scroll-restoration'
import { validateIfCanJumpOnce } from '~/utils'
import { flags } from '~/utils/constants'

import Section from '../section'

// FIXME: this logic is used in several places, it should be shared
function productHasStock(product, handlesStock) {
  if (product.variantInStock) {
    return true
  }

  if (product.stock <= 0) {
    return false
  }

  const maximumSalesQuantity = product.maximumSalesQuantity ?? Number.MAX_SAFE_INTEGER

  const max = handlesStock ? Math.min(product.stock, maximumSalesQuantity) : maximumSalesQuantity

  const canJumpAtLeastOnce = validateIfCanJumpOnce(product.jumpFactor, product.minimumSalesQuantity, max)

  return canJumpAtLeastOnce
}

function FeaturedProducts() {
  const { data } = useQuery(featuredProductsQuery)

  const vendor = useVendor()

  const handlesStock = useFeature(flags.HANDLE_STOCK)

  // FIXME: control loading states and errors
  let featuredProducts = data?.vendor?.featuredProducts ?? []

  useScrollRestoration()

  if (isEmpty(featuredProducts)) {
    return null
  }

  if (vendor.settings?.catalog?.products?.sortOOOFeaturedProductsLast) {
    // To avoid modifying the original source
    featuredProducts = [...featuredProducts].sort((productA, productB) => {
      const productAHasStock = productHasStock(productA, handlesStock)

      const productBHasStock = productHasStock(productB, handlesStock)

      if (productAHasStock && !productBHasStock) {
        return -1
      }

      if (!productAHasStock && productBHasStock) {
        return 1
      }

      return 0
    })
  }

  return (
    <Section title="Productos destacados">
      <Catalog products={featuredProducts} />
    </Section>
  )
}

export default FeaturedProducts
